import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../../templates/layout-standard"
import Seo from "../../../components/seo"
import SideBar from '../../../components/sidebar';
import SixtyFivePlusNav from '../../../components/nav/SixtyFivePlus';
import UpNextCardNonStrapi from '../../../components/ui/UpNextCardNonStrapi';
import LearnMore from '../../../components/learnmore';
import SpeakWithAdvisor from '../../../components/ui/SpeakWithAdvisor';

const SixtyFivePlusLearn = ({ location }) => {

	return (
		<Layout location={location} heading1="65+ (first time enrolling)" heading2="Understanding your Medicare Options.">
			
			<Seo title="Learn How Medicare Works | 65+ | Medicare Basics" meta={[{description: 'As soon as you turn 64, you should start getting ready for Medicare. If you\'ve decided to enroll in Medicare, this timeline offers suggestions for what you need to do to stay on track.'}]} bodyclass="medicare-basics section2 subpage" />
			<SixtyFivePlusNav />
				
			<div className="constrained">
				<div className="content-constrained">
					<div className="flexwrap_subpage">

						<main>
							<h3>Know Your Medicare ABCs &amp; Part D</h3>
							
							<p>Medicare is divided into &lsquo;parts,&rsquo; covering different types of health benefits. These are commonly referred to as Medicare Part A, Part B, Part C, and Part D.</p>
							
							<div className="medicare-parts-explained">
								<h4>
									<div className="letterblock sugarmaplebg">
										<span>A</span>
									</div>
									Part A is for Hospital Care
								</h4>
								<p>Part A helps cover inpatient hospitalization, care in skilled nursing facilities, hospice care and some home health care, usually for no monthly premium.</p>
								<ul>
									<li>You may still have to pay out-of-pocket costs for some services, including a deductible.</li>
									<li>Most people who have been employed automatically get Part A. <a href="https://www.medicare.gov/basics/get-started-with-medicare/sign-up/how-do-i-sign-up-for-medicare" className="external">Find out if you are eligible</a>.</li>
								</ul>

								<h4>
									<div className="letterblock greenbg">
										<span>B</span>
									</div>
									Part B is for Doctor Visits
								</h4>
								<p>Part B helps cover medical services such as visits to a doctor&rsquo;s office, durable medical equipment and outpatient care.</p>
								<ul>
									<li>You may have to pay a percentage of the cost for some services, in addition to your yearly deductible.</li>
									<li>You usually pay a monthly premium, based on your income, which is typically deducted directly from your Social Security check. <a href="https://www.medicare.gov/basics/get-started-with-medicare/medicare-basics/what-does-medicare-cost" className="external">Get details</a>.</li>
									<li>If you&rsquo;re going to continue to receive coverage through your employer past age 65, you may decide you do not want to take Part B. <Link to="/medicare-basics/65-plus/know-what-to-do-when/">See your options</Link>.</li>
								</ul>

								<h4>
									<div className="letterblock champlainbg">
										<span>C</span>
									</div>
									Part C is a Medicare Advantage Plan
								</h4>
								<p>For many people, Original Medicare doesn&rsquo;t cover everything they want or need. A Medicare Advantage plan includes the benefits of Parts A and B, but can also offer extra benefits like dental and vision coverage.</p>
								<ul>
									<li>You must have Part A and Part B to enroll in C. <Link to="/enroll-now/am-i-eligible/">See if you&rsquo;re eligible</Link>.</li>
									<li>You usually pay a monthly premium to the health insurance company for a Part C plan, and continue to pay your Part B premium, which is deducted from your Social Security check each month.</li>
									<li>Medicare Advantage Plans are offered by private health insurance companies, like UVM Health Advantage. <Link to="/plans/uvm-health-advantage/">Explore these plans</Link>.</li>
									<li>Some even include Part D prescription drug coverage.</li>
								</ul>

								<h4>
									<div className="letterblock pumpkinbg">
										<span>D</span>
									</div>
									Part D is for Prescription Drug Coverage
								</h4>
								<p>Part D is prescription drug coverage that includes many commonly used brand name and generic drugs. Original Medicare does not include any coverage for prescription drugs. You need to have Part A and Part B to be eligible for Part D.</p>
								<ul>
									<li>There are two ways you can get Medicare Part D:
										<ol>
											<li>You enroll in a <Link to="/plans/uvm-health-advantage/">Medicare Advantage (Part C) plan</Link> that includes Part D coverage</li>
											<li>You enroll in a stand alone Part D plan</li>
										</ol>
									</li>
									<li>There is generally a monthly premium based on income for Part D coverage</li>
									<li>Based on income, you may pay a Part D surcharge to Medicare, deducted from your Social Security check</li>
									<li>If you are low income or are on both Medicare and Medicaid, you may be eligible for extra help with Part D drug coverage. <a href="https://www.medicare.gov/your-medicare-costs/get-help-paying-costs/find-your-level-of-extra-help-part-d" className="external">Get details</a>.</li>
								</ul>
								
								<p><Link to="/rx-information/">Learn more about UVM Health Advantage prescription coverage</Link></p>

								<p>NOTE: If you don&rsquo;t enroll in Part D when you first become eligible, or do not have coverage as good as Medicare&rsquo;s, you may have to pay a penalty if you join at a later date. <a href="https://www.medicare.gov/drug-coverage-part-d/costs-for-medicare-drug-coverage/part-d-late-enrollment-penalty" className="external">Get details</a>.</p>
							</div>

							<h3 className="small">Important to know!</h3>
							<p>Medicare allows you to be a member of only one Medicare Advantage plan at a time. If you join a Medicare Advantage plan and later choose a Part D drug plan from another insurance or pharmacy company, Medicare will:</p>
							<ol>
								<li>Enroll you in that drug plan for your Part D drug coverage,</li>
								<li>Automatically disenroll you out of your current Medicare Advantage plan, and</li>
								<li>Allow you to keep Medicare (Parts A and B) for your medical coverage for the remainder of the year, along with the other drug plan you chose.</li>
							</ol>

							<UpNextCardNonStrapi to="/medicare-basics/65-plus/know-what-to-do-when/">Know What To Do When</UpNextCardNonStrapi>
							<LearnMore />
							<SpeakWithAdvisor />
						</main>
						
						<SideBar location={location} />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default SixtyFivePlusLearn
